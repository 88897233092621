import React from 'react';
import { scroller } from 'react-scroll';
import { graphql } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

import ContactSection, { ContactSectionVariant } from 'components/molecules/ContactSection';
import Container from 'components/molecules/Container';
import FormSection, { FormSectionVariant } from 'components/molecules/FormSection';
import PhotoGallery from 'components/molecules/PhotoGallery';
import Banner from 'components/organisms/Banner';
import BenefitsSection from 'components/organisms/BenefitsSection';
import ServicesSection from 'components/organisms/ServicesSection';
import { StyledCareersServicesContainer } from 'components/templates/containers';
import Layout from 'components/templates/Layout';

const CareersDetailPage = ({
  data: {
    contentfulCareersPage: {
      featureList,
      benefitsAndPerksList,
      seo,
      banner,
      formSection,
      contactSection,
      carousel,
    },
  },
}: CareersPageTypes.CareersPageProps) => {
  const handleScroll = () => {
    if (formSection?.sectionId) {
      const sectionIdTransformed = kebabCase(formSection?.sectionId);

      scroller.scrollTo(sectionIdTransformed, {
        duration: 600,
        delay: 200,
        offset: -80,
        smooth: 'easeInOutQuart',
      });
    }
  };

  return (
    <Layout seo={seo} isFooterTop={false} bgColor="gray-50">
      <Container contentWidth={1280}>
        {banner ? (
          <Banner
            {...banner}
            scrollToSection={formSection?.sectionId}
            handleScroll={handleScroll}
          />
        ) : null}
      </Container>
      {featureList ? (
        <StyledCareersServicesContainer contentWidth={1280} mt={-128} mb={64}>
          <ServicesSection {...featureList} />
        </StyledCareersServicesContainer>
      ) : null}
      {benefitsAndPerksList ? (
        <Container
          contentWidth={1280}
          mb={{ base: 80, md: 96, lg: 136, xl: 200 }}
          mt={{ base: 80, md: 136 }}
        >
          <BenefitsSection {...benefitsAndPerksList} handleScroll={handleScroll} />
        </Container>
      ) : null}
      <Container noPadding mb={{ base: 80, md: 96, lg: 136, xl: 200 }}>
        <PhotoGallery {...carousel} />
      </Container>
      <Container contentWidth={1360} pb={{ base: 80, md: 96, lg: 136, xl: 200 }} mt={40}>
        <FormSection {...formSection} formVariant={FormSectionVariant.CAREERS} />
        <ContactSection {...contactSection} contactVariant={ContactSectionVariant.CAREERS} />
      </Container>
    </Layout>
  );
};

export default CareersDetailPage;

export const query = graphql`
  query CareersDetail($slug: String) {
    contentfulCareersPage(slug: { eq: $slug }) {
      seo {
        ...SeoFragment
      }
      banner {
        ...BannerFragment
      }
      featureList {
        ...FeatureSectionFragment
      }
      benefitsAndPerksList {
        ...FeatureSectionFragment
      }
      carousel {
        ...PhotoGalleryFragment
      }
      contactSection {
        ...ContactFooterStructureFragment
      }
      formSection {
        ...FormSectionStructureFragment
      }
    }
  }
`;
